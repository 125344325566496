import React, { useRef } from "react";
import { AttachmentIcon } from "../common/icons/svgIcons";
import { DropOffConstants } from "../helper/Constants";
import { Colors } from "../styles/ColorConstants";

export interface IFileUploadModel {
    file: File;
    uploadProgress: number;
    guid: string;
    isUploaded: boolean;
    fileSize?: number;
    fileId?: number;
}

interface IAttachmentUploaderProps {
    onDropFiles: (e: any, callback?: any) => void;
}

const AttachmentUploader: React.FC<IAttachmentUploaderProps> = (props) => {
    const inputFileRef: any = useRef();

    return (
        <>
            <label
                className="button-primary-blue-2-outlined attach-button"
                htmlFor="uploadFile"
            >
                <AttachmentIcon fillColor={Colors.$sapphire}/>
                {DropOffConstants.ATTACH_FILES}
            </label>
            <input
                ref={inputFileRef}
                type="file"
                id="uploadFile"
                onChange={(e) =>
                    props.onDropFiles(e, () => (inputFileRef.current.value = ""))
                }
                multiple
            />
        </>
    );
};

export default AttachmentUploader;
