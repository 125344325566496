import React, { FC, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import {
  AccessCardMenuItems,
  DocumentRequestMeuItems,
  LinkType,
  LogEventConstants,
  sessionStorageConstants,
} from "../../helper/Constants";
import AccessLinkContent from "./AccessLinkContent";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ApplicationState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { accessLinkParams } from "../SendLink";
import { requestScreen } from "../../store/actions/sendLinkActions";
import { logger } from "../../App";
import { isIphoneDevice, isMobile, isRequestLink, IsUteRequest } from "../../helper/HelperFunctions";
import "./AccessLinkLayout.scss";
import WelcomeScreen from "../common/WelcomeScreen";
import Header from "../Layout/Header";
import { StepComponent } from "../common/StepContainer";
import { StepWizard } from "../common/StepWizard";

interface IAccessLinkLayoutProps {
  type: LinkType;
}
const AccessLinkLayout: FC<IAccessLinkLayoutProps | any> = (props) => {
  const { type } = props;
  const menuKeys = !isRequestLink(type)
    ? AccessCardMenuItems
    : DocumentRequestMeuItems;
  const isMobileDevice = isMobile();
  const [activeMenu, setActiveMenu] = useState(isMobileDevice ? menuKeys[-1] : menuKeys[0]);
  const [activeMenuIndex, setActiveMenuIndex] = useState(isMobileDevice ? -1 : 0);
  const [previousLocationClientId, setPreviousLocationClientId] = useState("");
  const isScreenLoadCompleted = useSelector(
    (state: ApplicationState) => state.sendLink.isRequestScreenCompleted
  );
  const { clientId }: accessLinkParams = useParams();
  const headerInfo = useSelector((appState: ApplicationState) => appState.sendLink.headerInfo);
  const encodedEmail = props.match.params.encodedEmail;

  const history = useHistory();
  const dispatch = useDispatch();
  const pageTitle = !isRequestLink(type)
    ? "AccessLinkLayout page"
    : "DocumentRequestLayout Page";
  const { fromUte }: accessLinkParams = useParams();

  const getScreen = () => {
    dispatch(
      requestScreen(
        clientId,
        (data: any) => {
          if (IsUteRequest(clientId, fromUte)) {
            sessionStorage.setItem(sessionStorageConstants.accessTokenForUte, data.accessToken);
            sessionStorage.setItem(sessionStorageConstants.uteClientId, clientId);
          }
        },
        () => history.push(`/Error`),
        type
      )
    );
  };

  useEffect(() => {
    logger.trackPageView(pageTitle);
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Common.Open} ${pageTitle}`, {
        page: pageTitle,
        clientId: clientId,
      })
    );

    getScreen();

    if (IsUteRequest(clientId, fromUte)) {
      fromUte && fromUte !== "undefined" && sessionStorage.setItem(sessionStorageConstants.fromUte, fromUte);
      setActiveMenu("Upload");
      setActiveMenuIndex(2);
    } else {
      if (activeMenuIndex === 0 && sessionStorage.getItem("previousClientId")) {
        const previousClientId = sessionStorage.getItem("previousClientId");
        history.push(
          `/${!isRequestLink(type) ? "SendLinkRedirect" : "DocumentRequest"
          }/${previousClientId}`
        );
      }

      return () => {
        sessionStorage.removeItem("previousClientId");
      };
    }
  }, []);

  const handleGetStarted = () => {
    setActiveMenu("Verify");
    setActiveMenuIndex(0);
  };

  return isScreenLoadCompleted ? (
    <div id={"layout"} className={`access-link-layout ${isMobileDevice && "mobile-access-link-layout"} ${isIphoneDevice() && "iphone-devices"}`}>
      {isMobileDevice && <Header
        headerInfo={headerInfo}
        clientId={clientId}
        encodedEmail={encodedEmail}
        hideProductLogo={true}
        showCompanyWhiteLogo={activeMenuIndex === -1}
        hideUTELink={true}
        className={`header-2 ${!isRequestLink(type) ? "download-header" : ""}`}
        showAlternateProfileIcon
      >
        {activeMenuIndex !== -1 && <StepComponent isAbsolute menuKeys={menuKeys} activeMenuIndex={activeMenuIndex} />}
      </Header>}
      {
        activeMenuIndex === -1 ? <WelcomeScreen menuKeys={menuKeys}
          handleGetStarted={handleGetStarted}
        /> :
          <div
            className={`access-link-container ${activeMenu === "AccessCode" ? "access-code-page-container" : ""} ${activeMenu === "Upload" ? "upload-page-container" : ""}`}
          >
            <Row
              className={`access-link-header `}
              style={{ display: IsUteRequest(clientId, fromUte) ? "none" : "" }}
            >
            {!isMobileDevice && <StepWizard menuKeys={menuKeys} activeMenuIndex={activeMenuIndex} />          }
            </Row>
            <Row
              className={`${activeMenuIndex === 2 ? "access-link-content" : ""}`}
              style={{ marginTop: IsUteRequest(clientId, fromUte) ? "4rem" : "" }}
            >

              <AccessLinkContent
                type={type}
                eventKey={activeMenu}
                previousLocationClientId={previousLocationClientId}
                setPreviousLocationClientId={setPreviousLocationClientId}
                setActiveMenu={setActiveMenu}
                setActiveMenuIndex={setActiveMenuIndex}
              />
            </Row>
          </div>
      }
    </div>
  ) : (
    <LoadingOverlay>
      <Loader text="" loading={true} />
    </LoadingOverlay>
  );
};

export default AccessLinkLayout;
