import React, { useState, useEffect, useRef } from 'react';

interface DraggableDivProps {
  children: React.ReactNode;
  initialPosition?: { x: number; y: number };
}

const DraggableDiv:React.FC<DraggableDivProps> = ({ children, initialPosition = { x: 300, y: 200 } }) => {
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [initialTouch, setInitialTouch] = useState({ x: 0, y: 0 });
  const [isIconMoved, setIsIconMoved] = useState(false);

  const divRef = useRef(null);

  // Prevent default touch behaviors
  useEffect(() => {
    const div = divRef.current;
    const preventDefaultTouch = (e) => {
      if (isDragging) {
        e.preventDefault();
      }
    };

    // Add these options to ensure touch events are captured before browser actions
    div?.addEventListener('touchstart', preventDefaultTouch, { passive: false });
    div?.addEventListener('touchmove', preventDefaultTouch, { passive: false });
    document.addEventListener('touchmove', preventDefaultTouch, { passive: false });

    return () => {
      div?.removeEventListener('touchstart', preventDefaultTouch);
      div?.removeEventListener('touchmove', preventDefaultTouch);
      document.removeEventListener('touchmove', preventDefaultTouch);
    };
  }, [isDragging]);

  // Track touch/mouse events
  useEffect(() => {
    const handleMove = (e) => {
      if (!isDragging) return;

      e.preventDefault();
      const isTouchEvent = e.touches && e.touches.length > 0;
      const clientX = isTouchEvent ? e.touches[0].clientX : e.clientX;
      const clientY = isTouchEvent ? e.touches[0].clientY : e.clientY;

      const deltaX = clientX - dragStart.x;
      const deltaY = clientY - dragStart.y;

      const newX = position.x + deltaX;
      const newY = position.y + deltaY;

      // Get layout dimensions
      const layout = document.getElementById('layout');
      const header = document.getElementById('header');
      const footer = document.getElementById('footer');

      const layoutRect = layout.getBoundingClientRect();
      const headerRect = header.getBoundingClientRect();
      const footerRect = footer.getBoundingClientRect();

      // Calculate allowed area
      const minX = layoutRect.left;
      const maxX = layoutRect.right - divRef.current.offsetWidth;
      const minY = headerRect.bottom;
      const maxY = footerRect.top - divRef.current.offsetHeight;

      // Check boundaries
      if (newX >= minX && newX <= maxX && newY >= minY && newY <= maxY) {
        setPosition({ x: newX, y: newY });
        setDragStart({ x: clientX, y: clientY });
      }
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchmove', handleMove, { passive: false });
      document.addEventListener('touchend', handleEnd);
      document.addEventListener('touchcancel', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
      document.removeEventListener('touchcancel', handleEnd);
    };
  }, [isDragging, dragStart, initialTouch]);

  const handleStart = (e) => {
    const isTouchEvent = e.touches && e.touches.length > 0;
    const clientX = isTouchEvent ? e.touches[0].clientX : e.clientX;
    const clientY = isTouchEvent ? e.touches[0].clientY : e.clientY;
    setIsIconMoved(true);
    setIsDragging(true);
    setDragStart({ x: clientX, y: clientY });
    
    // Store the initial position for touch events
    if (isTouchEvent) {
      setInitialTouch(position);
    }
  };

  return (
    <div
      ref={divRef}
      onMouseDown={handleStart}
      onTouchStart={handleStart}
      style={{
        position: 'absolute',
        left: !isIconMoved ? "80vw" :`${position.x}px`,
        top: !isIconMoved? "42%": `${position.y}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
        userSelect: 'none',
        touchAction: 'none',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
        transition: isDragging ? 'none' : 'box-shadow 0.2s ease',
        transform: 'translate3d(0,0,0)', // Force hardware acceleration
        zIndex: isDragging ? 1000 : 1
      }}
    >
      {children}
    </div>
  );
};

export default DraggableDiv;