import React, { FC, useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable, {
  ColumnDescription,
  ColumnFormatter,
} from "react-bootstrap-table-next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import {
  ChatMessageIcon,
  DeleteIcon,
  ExclamationIcon,
  ExclamationTriangleIcon,
  MinusIcon,
  PlusIcon,
  SuccessIcon,
  UploadIcon,
} from "../common/icons/svgIcons";
import { LinkType, PlaceholderText, sessionStorageConstants, UploadConstants } from "../helper/Constants";
import {
  getFileName,
  getFormattedData,
  isMobile,
  IsUteRequest,
} from "../helper/HelperFunctions";
import { validateDRLFileSize } from "../helper/Validations";
import {
  addFileToDocument,
  completeUpload,
  fetchUploadDocumenttSasLink,
  getDocumentRequestDetail,
  deleteAllRequestedFiles,
  deleteRequestedFile,
} from "../store/actions/uploadActions";
import { CustomModal } from "./CustomModal";
import { accessLinkParams } from "./SendLink";
import { logger } from "../App";
import {
  DetailedStatus,
  IDocumentRequestFile,
  IDocumentRequestFileDetails,
  IDocumentRequestModel,
  initialDocumentRequestFileDetails,
  initialDocumentState,
  IUploadTableData,
} from "../core/viewModels/DocumentRequestModel";
import { IFileUploadModel } from "../core/viewModels/FileModel";
import { AppNotifier } from "../helper/AppNotifier";
import { initialUserModel, IUserModel } from "../core/viewModels/UserViewModel";
import { MessageModal } from "./MessageModal";
import { Markup } from "interweave";
import { ToasterMessages } from "../helper/ToasterMessages";
import { UploadFunctions } from "@sssuite-js-packages/file-utility";
import { getClientHubDomainURL } from "../store/actions/oneHubActions";
import { Colors } from "../styles/ColorConstants";
import DraggableDiv from "./common/Draggable";

interface IUploadProps {
  setActiveMenu?: React.Dispatch<React.SetStateAction<string>>;
  setActiveMenuIndex?: React.Dispatch<React.SetStateAction<number>>;
}
const Upload: FC<IUploadProps> = (props) => {
  const fileUploadPageTitle = "FileUpload page";
  const fetchDocumentPageTitle = "Fetch Document Details Page";
  const deletePageTitle = "DeleteFile Page";
  const uploadDonePageTitle = "Upload Done Page";
  const uploadCompletePageTitle = "Upload Complete Page";
  const onUploadFilePage = "Upload File";
  const fileUploadUtilities = new UploadFunctions();
  const isMobileDevice = isMobile();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [documentRequestData, setDocumentRequestData] =
    useState<IDocumentRequestModel>(initialDocumentState);
  const [sender, setSender] = useState<IUserModel>(initialUserModel);
  const [tableData, setTableData] = useState<IUploadTableData[]>([]);
  const [totalFileSize, setTotalFileSize] = useState<number>(0);
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const [messageModelVisited, setMessageModelVisited] = useState<boolean>(false);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [filesUploaded, setFilesUploaded] = useState<number>(0);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [nonExpandedRows, setNonExpandedRows] = useState<number[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientId }: accessLinkParams = useParams();

  const requestedCount = documentRequestData.requestedFiles.length || 0;
  const documentRef = React.useRef<IDocumentRequestModel>();
  documentRef.current = documentRequestData;
  useEffect(() => {
    setIsLoading(true);
    fetchDocumentDetails();
  }, []);

  useEffect(() => {
    if (documentRequestData.message?.length > 0 && !messageModelVisited) {
      setShowMessageModal(true);
      setMessageModelVisited(true);
    }
  }, [documentRequestData.message, messageModelVisited]);

  useEffect(() => {
    const disabled = documentRef.current.status === DetailedStatus.COMPLETED;
    setIsDisabled(disabled);

    // ADDING DISABLED KEY TO THE TABLE DATA
    const updatedTableData = documentRef.current.requestedFiles.map(
      (data, rowIndex) => {
        return { ...data, disabled, id: rowIndex };
      }
    );

    let total = 0;
    let uploadedCount = 0;

    documentRef.current.requestedFiles.map((data, rowIndex: number) => {
      if (data.uploadedFiles && data.uploadedFiles.length > 0) {
        data.uploadedFiles.forEach((file) => {
          total = total + file.fileSize;
        });
        if (
          data.uploadedFiles &&
          data.uploadedFiles.some((file) => file.fileId > 0)
        ) {
          uploadedCount += 1;
        }
      } else {
        handleNonExpandedRows(rowIndex);
      }
    });
    setTotalFileSize(total);
    setTotalFiles(documentRef.current?.requestedFiles?.length);
    setFilesUploaded(uploadedCount);
    setTableData(updatedTableData);
  }, [documentRef.current]);

  useEffect(() => {
    if (filesUploaded === expandedRows?.length) {
      setIsExpanded(true);
    }
    if (expandedRows.length === 0) {
      setIsExpanded(false);
    }
  }, [expandedRows, filesUploaded]);

  const handleNonExpandedRows = (rowIndex: number) => {
    setNonExpandedRows((nonExpandedRows) => {
      if (!nonExpandedRows.includes(rowIndex)) {
        return [...nonExpandedRows, rowIndex];
      }
      return nonExpandedRows;
    });
  };

  const handleDRLDetails = (data: any) => {
    setNonExpandedRows([]);
    setDocumentRequestData(data.documentRequest);
    setSender(data.sender);
    setIsLoading(false);
  };

  const handleError = () => {
    logger.trackEvent(
      logger.buildEvent(`${fileUploadPageTitle}: Upload file failed`, {
        page: fileUploadPageTitle,
        clientId: clientId,
      })
    );
    //TODO: Need to check if alert can be replaced by a toaster or something
    alert("Error occurred");
    setIsLoading(false);
  };

  const fetchDocumentDetails = () => {
    setIsLoading(true);
    logger.trackEvent(
      logger.buildEvent(`${fetchDocumentPageTitle}: Fetch Document Details`, {
        page: fetchDocumentPageTitle,
        clientId: clientId,
      })
    );
    dispatch(getDocumentRequestDetail(clientId, handleDRLDetails, handleError));
  };

  const fileNameformatter: ColumnFormatter<any, any, any> = (value, row) => {
    return (
      <div className="file-name-with-status-cell" title={value}>
        <span className="ellipsis">{value}</span>
      </div>
    );
  };

  const categoryNameformatter: ColumnFormatter<any, any, any> = (
    value,
    row
  ) => {
    return (
      <div className="file-name-with-status-cell" title={value}>
        <span className="ellipsis">{value || "-"}</span>
      </div>
    );
  };

  const uploadProgressCallback = (
    documentRequestFileId: number,
    percent: number,
    fileToUpload: IDocumentRequestFileDetails | any
  ) => {
    if (documentRef.current && documentRef.current.requestedFiles) {
      const updatedDocumentRequestData = documentRef.current.requestedFiles.map(
        (item: IDocumentRequestFile) => {
          if (item.documentRequestFileId === documentRequestFileId) {
            return {
              ...item,
              uploadedFiles: item?.uploadedFiles?.map(
                (file: IDocumentRequestFileDetails) => {
                  if (file.fileGuid === fileToUpload.fileGuid) {
                    return {
                      ...file,
                      uploadProgress: percent,
                    };
                  }
                  return file;
                }
              ),
            };
          }
          return item;
        }
      );
      setDocumentRequestData({
        ...documentRequestData,
        requestedFiles: updatedDocumentRequestData,
      });
    }
  };

  const uploadCompleteCallback = (
    documentRequestFileId: number,
    fileToUpload: any,
    callback?: () => void
  ) => {
    if (fileToUpload.uploadFailed) {
      const updatedDocumentRequestData = documentRef.current.requestedFiles.map(
        (item: IDocumentRequestFile) => {
          if (item.documentRequestFileId === documentRequestFileId) {
            return {
              ...item,
              uploadedFiles: item.uploadedFiles.filter(
                (file) => file.fileGuid !== fileToUpload.guid
              ),
            };
          }
          return item;
        }
      );
      setDocumentRequestData({
        ...documentRequestData,
        requestedFiles: updatedDocumentRequestData,
      });
      return;
    }
    dispatch(
      addFileToDocument(
        documentRequestFileId,
        clientId,
        fileToUpload,
        () => {
          const updatedDocumentRequestData =
            documentRef.current.requestedFiles.map(
              (item: IDocumentRequestFile) => {
                if (item.documentRequestFileId === documentRequestFileId) {
                  return {
                    ...item,
                    uploadedFiles: item?.uploadedFiles?.map((file) => {
                      if (file.fileGuid === fileToUpload.guid) {
                        return {
                          ...file,
                          fileId: fileToUpload.fileId,
                          isUploaded: true,
                          uploadProgress: 100,
                        };
                      }
                      return file;
                    }),
                  };
                }
                return item;
              }
            );
          setDocumentRequestData({
            ...documentRequestData,
            requestedFiles: updatedDocumentRequestData,
          });
          fetchDocumentDetails();
          setIsUploading(false);
          logger.trackEvent(
            logger.buildEvent(`${uploadCompletePageTitle}: upload Completed`, {
              page: uploadCompletePageTitle,
              clientId: clientId,
              documentRequestFileId: documentRequestFileId,
              fileToUpload: fileToUpload,
            })
          );
        },
        (errorMsg, errorCode) => {
          setIsUploading(false);
          setIsLoading(false);
          if (errorCode === UploadConstants.UPLOAD_FILE_ERROR_CODE) {
            AppNotifier.Error(errorMsg);
          } else {
            callback && callback();
            AppNotifier.Warning(errorMsg);
          }
          logger.trackEvent(
            logger.buildEvent(`${uploadCompletePageTitle}: upload failed`, {
              page: uploadCompletePageTitle,
              clientId: clientId,
              documentRequestFileId: documentRequestFileId,
              fileToUpload: fileToUpload,
            })
          );
        }
      )
    );
  };

  const onUploadFile = (
    documents: IFileUploadModel[],
    documentRequestFileId: number,
    requestedFiles: IDocumentRequestFile[],
    callback?: (fileData: IFileUploadModel[], fileId: number) => void
  ) => {
    setDocumentRequestData({
      ...documentRequestData,
      requestedFiles: requestedFiles,
    });

    documents &&
      documents.forEach((document: IFileUploadModel) => {
        setIsUploading(true);
        dispatch(
          fetchUploadDocumenttSasLink(
            document.guid,
            clientId,
            (data: any) => {
              if (data.sas) {
                const fileName = getFileName(document.fileName);
                fileUploadUtilities.uploadFile(
                  document.file,
                  data,
                  fileName,
                  (percent, fileToUpload) =>
                    uploadProgressCallback(
                      documentRequestFileId,
                      percent,
                      fileToUpload
                    ),
                  (fileToUpload) => {
                    uploadCompleteCallback(
                      documentRequestFileId,
                      fileToUpload,
                      () => {
                        callback(documents, documentRequestFileId);
                        setIsUploading(false);
                      }
                    );
                  }
                );
                logger.trackEvent(
                  logger.buildEvent(`${onUploadFilePage}: upload Completed`, {
                    page: onUploadFilePage,
                    clientId: clientId,
                    documentRequestFileId: documentRequestFileId,
                  })
                );
                return;
              }
              setIsUploading(false);
              setIsLoading(false);
            },
            documentRequestData.sentDate,
            () => {
              const updatedDocumentRequestData: IDocumentRequestFile[] =
                documentRequestData.requestedFiles.map(
                  (doc: IDocumentRequestFile) => {
                    if (doc.documentRequestFileId === documentRequestFileId) {
                      return {
                        ...doc,
                        uploadedFiles: doc.uploadedFiles.filter((file) => {
                          if (file.fileGuid !== document.guid) {
                            return {
                              ...initialDocumentRequestFileDetails,
                              ...file,
                            };
                          }
                        }),
                      };
                    }
                    return doc;
                  }
                );
              setDocumentRequestData({
                ...documentRequestData,
                requestedFiles: updatedDocumentRequestData,
              });
              logger.trackEvent(
                logger.buildEvent(`${onUploadFilePage}: upload failed`, {
                  page: onUploadFilePage,
                  clientId: clientId,
                  documentRequestFileId: documentRequestFileId,
                })
              );
            }
          )
        );
      });
  };

  const getUniqueFileName = (fileName: string, files: any): string => {
    let count = 1;
    let uniqueFileName = fileName;
    while (files.some((file) => file.fileName === uniqueFileName)) {
      const extensionIndex = fileName.lastIndexOf(".");
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);
      uniqueFileName = `${name} (${count})${extension}`;
      count++;
    }
    return uniqueFileName;
  };
  const handleFileUpload = async (e: any, row: any) => {
    e.preventDefault();
    e.stopPropagation();
    setExpandedRows((expandedRows) => {
      if (!expandedRows.includes(row.id)) {
        return [...expandedRows, row.id];
      }
      return expandedRows;
    });
    setNonExpandedRows((nonExpandedRows) => {
      if (nonExpandedRows.includes(row.id)) {
        return nonExpandedRows.filter((x) => x !== row.id);
      }
      return nonExpandedRows;
    });
    let newFiles: IFileUploadModel[] = [];
    const uploadedFiles = e.target.files;
    let totalWithCurrent = totalFileSize;
    Array.from(uploadedFiles).forEach((file: File) => {
      totalWithCurrent += file.size;
      const uniqueFileName = getUniqueFileName(file.name, [
        ...row.uploadedFiles,
        ...newFiles,
      ]);
      setTotalFileSize(totalWithCurrent);
      if (validateDRLFileSize(totalWithCurrent, file.name)) {
        newFiles.push({
          file: file,
          uploadProgress: 0,
          guid: uuidv4(),
          isUploaded: false,
          fileName: uniqueFileName,
        });
      }
    });
    const updatedDocumentRequestData: IDocumentRequestFile[] =
      documentRequestData.requestedFiles.map((item: IDocumentRequestFile) => {
        if (item.documentRequestFileId === row.documentRequestFileId) {
          return {
            ...item,
            uploadedFiles:
              item?.uploadedFiles?.length > 0
                ? item?.uploadedFiles?.concat(
                  newFiles.map((file) => {
                    return {
                      ...initialDocumentRequestFileDetails,
                      ...file,
                    };
                  })
                )
                : newFiles.map((file) => {
                  return {
                    ...initialDocumentRequestFileDetails,
                    ...file,
                  };
                }),
          };
        }
        return item;
      });

    setDocumentRequestData((data) => {
      return {
        ...data,
        requestedFiles: updatedDocumentRequestData,
      };
    });
    await onUploadFile(
      newFiles,
      row.documentRequestFileId,
      updatedDocumentRequestData,
      manageFileUpload
    );
  };

  const manageFileUpload = (
    fileData: IFileUploadModel[],
    documentRequestFileId: number
  ) => {
    const updatedDocumentRequestDatas: IDocumentRequestFile[] =
      documentRequestData.requestedFiles.map((item: IDocumentRequestFile) => {
        if (item.documentRequestFileId === documentRequestFileId) {
          if (item.uploadedFiles && item.uploadedFiles.length > 0) {
            // Remove the files that were added
            const updatedUploadedFiles = item.uploadedFiles.filter(
              (file) =>
                !fileData.some((newFile) => newFile.fileId === file.fileId)
            );
            return {
              ...item,
              uploadedFiles: updatedUploadedFiles,
            };
          }
        }
        return item;
      });
    setDocumentRequestData((data) => {
      return {
        ...data,
        requestedFiles: updatedDocumentRequestDatas,
      };
    });
    fetchDocumentDetails();
  };

  const handleFileDelete = (
    e?: any,
    isFileLevelOperation?: boolean,
    file?: IFileUploadModel
  ) => {
    if (e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    const selectedFile = documentRequestData.requestedFiles.filter(
      (file) =>
        selectedRow &&
        file.documentRequestFileId === selectedRow.documentRequestFileId
    )[0];
    if (isFileLevelOperation && file) {
      setIsLoading(true);

      dispatch(
        deleteRequestedFile(
          clientId,
          documentRequestData.sentDate,
          file,
          () => {
            fetchDocumentDetails();
            AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_FILE);
            logger.trackEvent(
              logger.buildEvent(
                `${deletePageTitle}: file delete successfully`,
                {
                  page: deletePageTitle,
                  clientId: clientId,
                }
              )
            );
            setIsLoading(false);
          },
          (errorMsg) => {
            setIsLoading(false);
            fetchDocumentDetails();
            AppNotifier.Warning(errorMsg);
            logger.trackEvent(
              logger.buildEvent(`${deletePageTitle}: file delete failed`, {
                page: deletePageTitle,
                clientId: clientId,
              })
            );
            setIsLoading(false);
          }
        )
      );

      return;
    }

    if (selectedRow) {
      setShowDeleteModal(false);
      setIsLoading(true);
      dispatch(
        deleteAllRequestedFiles(
          clientId,
          documentRequestData.sentDate,
          selectedFile,
          () => {
            AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_FILES);
            fetchDocumentDetails();
            logger.trackEvent(
              logger.buildEvent(
                `${deletePageTitle}: file delete all successfully`,
                {
                  page: deletePageTitle,
                  clientId: clientId,
                }
              )
            );
            setIsLoading(false);
          },
          (errorMsg) => {
            setIsLoading(false);
            fetchDocumentDetails();
            AppNotifier.Warning(errorMsg);
            logger.trackEvent(
              logger.buildEvent(`${deletePageTitle}: file delete All failed`, {
                page: deletePageTitle,
                clientId: clientId,
              })
            );
          }
        )
      );
      return;
    }
  };

  const handleUploadDone = () => {
    dispatch(
      completeUpload(
        clientId,
        documentRequestData.documentRequestId,
        () => {
          setShowConfirmModal(false);
          fetchDocumentDetails();
          AppNotifier.Success(
            isPartialUpload()
              ? ToasterMessages.SUCCESS.PARTIAL_UPLOAD_DONE
              : ToasterMessages.SUCCESS.FULLY_UPLOAD_DONE
          );
          logger.trackEvent(
            logger.buildEvent(`${uploadDonePageTitle}: upload done`, {
              page: uploadDonePageTitle,
              clientId: clientId,
            })
          );
        },
        (errorMsg: string) => {
          setIsLoading(false);
          setShowConfirmModal(false);
          AppNotifier.Warning(errorMsg);
          fetchDocumentDetails();
          logger.trackEvent(
            logger.buildEvent(`${uploadDonePageTitle}: upload failed`, {
              page: uploadDonePageTitle,
              clientId: clientId,
            })
          );
        }
      )
    );
  };

  const isPartialUpload = () => filesUploaded !== requestedCount;

  const getConfirmationText = () => {
    if (!isPartialUpload()) {
      return UploadConstants.CONFIRMATION_TEXT_ALL_UPLOADED;
    } else {
      return `You have uploaded only ${filesUploaded} of ${requestedCount} from the requested document(s).\nOn confirmation you will not be able to make any further changes.\nDo you wish to continue?`;
    }
  };

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        //Do not allow to go back on previous screen
        history.push(`/DocumentRequest/${clientId}`);
        props.setActiveMenu("Upload");
        props.setActiveMenuIndex(2);
      }
    };
  });

  window.addEventListener("popstate", function (event) {
    if (IsUteRequest(clientId)) {
      const uteInfo = sessionStorage.getItem(sessionStorageConstants.fromUte);
      const linkType = uteInfo
        ? LinkType.DocumentRequestLink
        : LinkType.CompanyDropOff;
      dispatch(
        getClientHubDomainURL(
          clientId,
          uteInfo,
          linkType,
          (oneHubDomainURL: string) => {
            //remove ute info before redirecting to onehub
            sessionStorage.removeItem(sessionStorageConstants.fromUte);
            window.location.href = oneHubDomainURL;
          }
        )
      );
    }
  });


  const columns: ColumnDescription[] = [
    {
      dataField: "documentCategoryName",
      text: isMobileDevice ? "Category" : "Document Category",
      formatter: categoryNameformatter,
      headerStyle: { width: "20%", paddingLeft: "0px", display: isMobileDevice ? "none" : "table-cell" },
      style: { width: "20%", paddingLeft: "0px", display: isMobileDevice ? "none" : "table-cell" },
    },
    {
      dataField: "name",
      text: isMobileDevice ? "Name" : "Document Name",
      formatter: fileNameformatter,
      headerStyle: { width: isMobileDevice ? "29%" : "20%", paddingLeft: "0px" },
      style: { width: isMobileDevice ? "29%" : "20%", paddingLeft: "0px" },
    },
    {
      dataField: "description",
      text: "Description",
      classes: "ellipsis",
      formatter: (value, row) => (
        <div className="ellipsis" title={value}>
          {value ? value : "-"}
        </div>
      ),
      headerStyle: { width: isMobileDevice ? "30%" : "25%" },
      style: { width: isMobileDevice ? "30%" : "25%" },
    },
    {
      dataField: "",
      text: "File(s)",
      classes: "ellipsis",
      formatter: (value, row) => <span>{row.uploadedFiles?.length ?? 0}</span>,

      headerStyle: { width: isMobileDevice ? "0%" : "10%", display: isMobileDevice ? "none" : "table-cell" },
      style: { width: isMobileDevice ? "0%" : "10%", fontSize: "14px", display: isMobileDevice ? "none" : "table-cell" },
    },
    {
      dataField: "",
      text: "Status",
      formatter: (value, row) =>
        row.uploadedFiles?.length > 0 && row.uploadedFiles[0].fileId ? (
          <span title="">
            <SuccessIcon />
          </span>
        ) : (
          <span title="Pending Upload">
            <ExclamationTriangleIcon />
          </span>
        ),
      headerStyle: { width: isMobileDevice ? "15%" : "7%", textAlign: "center" },
      style: { width: isMobileDevice ? "15%" : "7%", textAlign: "center" },
    },
    {
      text: "Actions",
      dataField: "disabled",
      hidden: isDisabled,
      formatter: (value, row) => {
        if (value) {
          return <div />;
        } else {
          return (
            <div className="table-inline-actions">
              <span className="table-inline-icon-container" title="Upload">
                <input
                  id={`file-upload-${row.documentRequestFileId}`}
                  type="file"
                  hidden
                  multiple
                  onClick={(event) => {
                    event.currentTarget.value = "";
                  }}
                  onChange={(e) => handleFileUpload(e, row)}
                />
                <label
                  htmlFor={`file-upload-${row.documentRequestFileId}`}
                  className="file-upload-button"
                >
                  <UploadIcon width={isMobileDevice ? 20 : 16} height={isMobileDevice ? 20 : 16} fillColor="#05386b" />
                  <span className="table-icon-inline-text">
                    {UploadConstants.UPLOAD_BUTTON_TEXT}
                  </span>
                </label>
              </span>
              {row.uploadedFiles?.length > 0 && row.uploadedFiles[0].fileId ? (
                <span
                  className="table-inline-icon-container"
                  title="Delete"
                  onClick={
                    isUploading
                      ? () => {
                        AppNotifier.Warning(
                          ToasterMessages.WARNING.UPLOAD_IN_PROCESS
                        );
                      }
                      : (e) => {
                        e.stopPropagation();

                        setSelectedRow(row);
                        setShowDeleteModal(true);
                      }
                  }
                >
                  <label>
                    <DeleteIcon width={isMobileDevice ? 20 : 16} height={isMobileDevice ? 20 : 16} fillColor="#565A5E" />
                    <span className="table-icon-inline-text">
                      {UploadConstants.DELETE_ALL_BUTTON_TEXT}
                    </span>
                  </label>
                </span>
              ) : null}
            </div>
          );
        }
      },
      headerStyle: { width: isMobileDevice ? "26%" : "20%", textAlign: isMobileDevice ? "center" : "left" },
      style: { width: isMobileDevice ? "26%" : "20%", textAlign: isMobileDevice ? "center" : "left" },
    },
  ];

  const onMessageIconClick = () => {
    if (documentRequestData.message.length > 0) {
      setShowMessageModal(true);
    }
  };

  const handleExpandAll = () => {
    if (filesUploaded > 0) {
      if (isExpanded) {
        setExpandedRows([]);
      } else {
        setExpandedRows(
          tableData
            ?.filter((row) => row.uploadedFiles.length > 0)
            .map((row) => row.id) ?? []
        );
      }
      setIsExpanded(!isExpanded);
    }
  };

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      setExpandedRows([...expandedRows, row.id]);
    } else {
      setExpandedRows(expandedRows.filter((x) => x !== row.id));
    }
  };

  const expandRow: any = {
    renderer: (row) => (
      <div className="file-display-table-container">
        <table className="file-display-table">
          <thead>
            <tr>
              <th style={{ width: isMobileDevice ? "75%" : "65%" }}>File Name</th>
              <th style={{ width: isMobileDevice ? "24%" : "14%", display: isMobileDevice ? "none" : "table-cell" }}>Uploaded On</th>
              <th style={isDisabled ? { display: "none" } : { width: isMobileDevice ? "25%" : "17%" }}>
                {isMobileDevice ? "" : "Actions"}
              </th>
            </tr>
          </thead>
          <tbody>
            {row?.uploadedFiles?.map((file, index) => (
              <tr key={index + file.fileId}>
                <td style={{ width: isMobileDevice ? "75%" : "65%" }}>
                  <span className="ellipsis file-name" title={file.fileName}>
                    {file.fileName}
                  </span>
                </td>
                <td style={{ width: "14%", display: isMobileDevice ? "none" : "table-cell" }}>
                  {getFormattedData(file.uploadedDate)}
                </td>
                <td style={isDisabled ? { display: "none" } : { width: isMobileDevice ? "25%" : "17%" }}>
                  {file.uploadProgress === 100 || file.fileId > 0 ? (
                    <span
                      className="table-inline-icon-container"
                      title="Delete"
                      onClick={(e) => {
                        setSelectedRow(row);
                        handleFileDelete(e, true, file);
                      }}
                    >
                      <label>
                        <DeleteIcon
                          width={isMobileDevice ? 20 : 16}
                          height={isMobileDevice ? 20 : 16}
                          fillColor="#565A5E"
                        />
                        <span className="table-icon-inline-text">
                          {UploadConstants.DELETE_BUTTON_TEXT}
                        </span>
                      </label>
                    </span>
                  ) : (
                    <span className="fas fa-spin fa-circle-notch" style={{ margin: isMobileDevice ? "4px 0px 0px 20px" : "0" }}></span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
    expanded: expandedRows,
    expandColumnPosition: "right",
    onExpand: handleOnExpand,
    showExpandColumn: true,
    nonExpandable: nonExpandedRows,
    expandHeaderColumnRenderer: ({ }) => {
      return <></>;
    },
    expandColumnRenderer: ({ expanded, expandable }) => {
      console.log("Expandable", expandable);
      if (expanded) {
        return (
          <span className="expand-collapse-icon">
            <MinusIcon />
          </span>
        );
      }
      return (
        <span
          className={`expand-collapse-icon ${expandable ? "" : "disabled"}`}
        >
          <PlusIcon fillColor={expandable ? Colors.$navy : Colors["$gray-200"]} />
        </span>
      );
    },
  };

  return (
    <div className={`${isMobileDevice && "mobile-upload-container"} upload-container`}>
      <div className="row">
        <div className="title-container">
          <span className="files-text">
            {UploadConstants.REQUESTED_FILES_TEXT}
            <span className="files-count">
              {" "}
              ({filesUploaded}/{totalFiles}){" "}
            </span>

            <OverlayTrigger
              placement={isMobileDevice ? "bottom" : "right"}
              overlay={
                <Tooltip id={"tooltip-info"}>
                  {UploadConstants.MAX_FILE_SIZE_TOOLTIP}
                </Tooltip>
              }
            >
              <span>
                <ExclamationIcon className="info-icon" />
              </span>
            </OverlayTrigger>
          </span>
          {
            isMobileDevice ? <DraggableDiv>
              <span
                className={`message-icon-container ${documentRequestData.message.length > 0 ? "" : "disabled"
                  }`}
                title={documentRequestData.message.length > 0 ? UploadConstants.MESSAGE_ICON_TOOLTIP : UploadConstants.NO_MESSAGE_ICON_TOOLTIP}
                onClick={onMessageIconClick}
              >
                <ChatMessageIcon fillColor={Colors.$sapphire} />
                <span className="view-cpa-note-text">
                  View
                  <br />
                  CPA
                  <br />
                  Note
                </span>
              </span>
            </DraggableDiv> :
              <span
                className={`message-icon-container ${documentRequestData.message.length > 0 ? "" : "disabled"
                  }`}
                style={{ position: "absolute", top: "42%", right: "0" }}
                title={documentRequestData.message.length > 0 ? UploadConstants.MESSAGE_ICON_TOOLTIP : UploadConstants.NO_MESSAGE_ICON_TOOLTIP}
                onClick={onMessageIconClick}
              >
                <ChatMessageIcon fillColor={Colors.$sapphire} />
                <span className="view-cpa-note-text">
                  View
                  <br />
                  CPA
                  <br />
                  Note
                </span>
              </span>
          }
          <span
            className={`expand-text ${filesUploaded === 0 ? "disabled" : ""}`}
            onClick={handleExpandAll}
          >
            {isExpanded
              ? isMobileDevice ? UploadConstants.COLLAPSE_TEXT : UploadConstants.COLLAPSE_ALL_TEXT
              : isMobileDevice ? UploadConstants.EXPAND_TEXT : UploadConstants.EXPAND_ALL_TEXT}
          </span>
        </div>
      </div>
      <div className="upload-table-wrapper">
        {documentRequestData.dueDate && (
          <div className="due-date-wrapper">
            <span className="due-on-text">Due on:</span>{" "}
            <span className="due-date-text">
              {getFormattedData(documentRequestData?.dueDate.toString())}
            </span>
          </div>
        )}
        <div>
          <LoadingOverlay>
            <div
              className={`fixed-height-wrapper ${documentRequestData.dueDate && "due-date-table-height"
                }`}
            >
              <BootstrapTable
                bordered={false}
                classes="file-upload-table primary-table"
                keyField="id"
                columns={columns}
                data={tableData}
                expandRow={expandRow}
                remote
              />
            </div>
            <Loader loading={isLoading} />
          </LoadingOverlay>
        </div>
      </div>
      <div className="download-btn-div">
        {!isDisabled && (
          <Button
            size="sm"
            className={`${isMobileDevice ? "button-primary-green" : "button-primary-blue-2"}`}
            onClick={
              isUploading
                ? () => {
                  AppNotifier.Warning(
                    ToasterMessages.WARNING.UPLOAD_IN_PROCESS
                  );
                }
                : () => setShowConfirmModal(true)
            }
            disabled={isLoading}
          >
            {isMobileDevice ? UploadConstants.SUBMIT : UploadConstants.UPLOAD_DONE_TEXT}
          </Button>
        )}
      </div>
      <CustomModal
        show={showDeleteModal}
        className="file-delete-modal"
        onHide={() => setShowDeleteModal(false)}
        cancelButtonName="Cancel"
        confirmButtonName="Yes, Delete It"
        title={UploadConstants.DELETE_TITLE}
        onSubmit={handleFileDelete}
        isConfirmModal={true}
      >
        {UploadConstants.DELETE_TEXT}
      </CustomModal>
      <CustomModal
        show={showConfirmModal}
        className="upload-done-confirm-modal"
        onHide={() => setShowConfirmModal(false)}
        cancelButtonName="Cancel"
        confirmButtonName="Finish"
        title="Submit Request"
        onSubmit={handleUploadDone}
        isConfirmModal={true}
      >
        {getConfirmationText()}
      </CustomModal>
      {showMessageModal && (
        <MessageModal
          show={showMessageModal}
          onHide={() => {
            setShowMessageModal(false);
          }}
          onSubmit={() => {
            setShowMessageModal(false);
          }}
          showAlert={true}
          title={UploadConstants.MESSAGE_MODAL_TITLE}
          className="drawer-right-modal"
          confirmButtonName={UploadConstants.MESSAGE_MODAL_CONFIRM_BUTTON}
        >
          <div>
            <Markup
              className="sender-message"
              content={
                documentRequestData.message || PlaceholderText.NO_MESSAGE
              }
            />
          </div>
        </MessageModal>
      )}
    </div>
  );
};

export default Upload;
