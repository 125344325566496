import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import OtpInputControl from "./common/OtpInputControl";
import {
  generateOTP,
  validatePage,
  verifyOTP,
} from "../store/actions/otpActions";
import { AppNotifier } from "../helper/AppNotifier";
import { LogEventConstants, OTPPageConstants } from "../helper/Constants";
import { isMobile, isRequestLink, storeTokenInMemory } from "../helper/HelperFunctions";
import { logger } from "../App";
import { accessLinkParams } from "./SendLink";
import { useHistory, useParams } from "react-router";
import { SecurityShieldIcon } from "../common/icons/svgIcons";
import { ToasterMessages } from "../helper/ToasterMessages";
import { ApplicationState } from "../store";
import ProfileDropdown from "./Layout/ProfileDropdown";

export interface IOTPValidation {
  otpVal: string;
  otpInvalidMessage?: string;
}

const OTPValidation: React.FC = (props: any) => {
  const pageTitle = "OTPValidation page";
  const history = useHistory();
  const isMobileDevice = isMobile();
  const [state, setState] = React.useState<IOTPValidation>({
    otpVal: "",
    otpInvalidMessage: "",
  });
  const headerInfo = useSelector((appState: ApplicationState) => appState.sendLink.headerInfo);

  const continueState = state.otpVal.length === 8;

  const dispatch = useDispatch();

  const { clientId }: accessLinkParams = useParams();
  const type = props.type;

  useEffect(() => {
    logger.trackPageView(pageTitle);
    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Common.Open} ${pageTitle}`, {
        page: pageTitle,
        clientId: clientId,
      })
    );
    dispatch(validatePage(clientId, handleSuccess, handleError, props.type));
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        //Go Back to Verify Screen
        history.replace(`/${isRequestLink(type) ? "DocumentRequest" : "SendLinkRedirect"}/${props.previousLocationClientId}`)
        props.setActiveMenu("Verify");
        props.setActiveMenuIndex(0);
      }
    }
  });

  const handleOTPChange = (otp: string) => {
    setState((state) => ({ ...state, otpVal: otp }));
  };

  const handleOTPVerification = (result: any) => {
    storeTokenInMemory(result.data.messageGuid, result.data.accessToken);
    logger.trackEvent(
      logger.buildEvent(
        `${pageTitle}: otp verification finished and redirecting to download`,
        {
          page: pageTitle,
          clientId: clientId,
          messageGuid: result.messageGuid,
          otpInput: state.otpVal,
        }
      )
    );
    props.setActiveMenu(isRequestLink(type) ? "Upload" : "Download");
    props.setActiveMenuIndex(2);
  };

  const handleError = (result: any) => {
    setState((state) => ({ ...state, otpVal: "" }));
    if (
      result.errorCode &&
      (result.errorCode === "ERROR_INVALID_OTP" ||
        result.errorCode === "OTP_EXPIRED")
    ) {
      logger.trackEvent(
        logger.buildEvent(
          `${pageTitle}: otp verification failed for incorrect/expired otp`,
          {
            page: pageTitle,
            clientId: clientId,
            otpInput: state.otpVal,
            errorCode: result.errorCode,
          }
        )
      );
      result.errorCode === "OTP_EXPIRED" ? AppNotifier.Error(ToasterMessages.ERROR.EXPIRE_OTP)
        : setState((state) => ({ ...state, otpInvalidMessage: result.errorDescription }));
    } else {
      logger.trackEvent(
        logger.buildEvent(`${pageTitle}: otp validation fail`, {
          page: pageTitle,
          clientId: clientId,
          otpInput: state.otpVal,
        })
      );
      history.push("/Error");
    }
  };

  const handleSuccess = () => {
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: validation success`, {
        page: pageTitle,
        clientId: clientId,
      })
    );
    dispatch(generateOTP(clientId, props.type));
  };

  const handleOTPVerify = () => {
    logger.trackEvent(
      logger.buildEvent(`${pageTitle}: otp submitted`, {
        page: pageTitle,
        clientId: clientId,
        otpInput: state.otpVal,
      })
    );
    continueState &&
      dispatch(
        verifyOTP(state.otpVal, clientId, handleOTPVerification, handleError, props.type)
      );
  };

  const requestCode = () => {
    dispatch(generateOTP(clientId, props.type, true));
  };

  return (
    <div className={`validation-container ${isMobileDevice && "mobile-auth-container mobile-auth-otp-container"}`}>
      <div className="top-container">
        {isMobileDevice ?
          <img src={headerInfo?.companyLogoPath} alt="company logo" className="company-logo" />
          : <SecurityShieldIcon />}
        <h4 className="card-title">{isMobileDevice ? OTPPageConstants.MOBILE_TITLE : OTPPageConstants.TITLE}</h4>

        {isMobileDevice ? <p className="card-sub-title">
          A One-Time Access Code has been <br/>sent to your email address; it <span className="otp-expiry-text">
            expires<br/>
            in 20 minutes.
          </span>
        </p> : <span><p className="card-sub-title">{OTPPageConstants.OtpSentMessageDownload}</p>
          <p className="otp-expiry-text">{OTPPageConstants.OtpExpiryMessage}</p>
        </span>
        }
      </div>
      <OtpInputControl
        otp={state.otpVal}
        setOtp={handleOTPChange}
        isInvalid={state.otpInvalidMessage !== ""}
        errorText={state.otpInvalidMessage}
      />
      {isMobileDevice ? <div className="bottom-container">
        <span className="spam-text">{OTPPageConstants.MOBILE_SPAM_WARNING}</span>
        <Button className="resend-btn" id="btn-resend-code" variant="link" onClick={requestCode}>
          {OTPPageConstants.RESEND_BUTTON_TITLE}
        </Button>
        <div>
          <Button
            variant="primary"
            size="sm"
            className="button-primary-blue-2"
            disabled={!continueState}
            onClick={handleOTPVerify}
          >
            {OTPPageConstants.CONFIRM_BUTTON_TITLE}
          </Button>
        </div>
        <p className="mobile-otp-junk-text">{OTPPageConstants.NEED_HELP}  <span className="mobile-otp-contact-us">
          <ProfileDropdown
            headerInfo={headerInfo}
            placement={"top"}
            showContactUsBtn={true} />
        </span></p>
      </div> : <div className="bottom-container">
        <div>
          <Button
            variant="primary"
            size="sm"
            className="button-primary-blue-2"
            disabled={!continueState}
            onClick={handleOTPVerify}
          >
            {OTPPageConstants.AUTHENTICATE_BUTTON_TITLE}
          </Button>
        </div>
        <span className="spam-text">{OTPPageConstants.SPAM_WARNING}</span>
        <Button className="resend-btn" id="btn-resend-code" variant="link" onClick={requestCode}>
          {OTPPageConstants.RESEND_BUTTON_TITLE}
        </Button>
      </div>}
    </div>
  );
};

export default OTPValidation;
