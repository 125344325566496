import React from "react";
import { IFileUploadModel } from "../../core/viewModels/FileModel";
import { AttachmentIcon, CloseXButton } from "../../common/icons/svgIcons";
import { formatBytes } from "../../helper/HelperFunctions";
import { Colors } from "../../styles/ColorConstants";

interface IAttachmentListItemProps {
  attachment: IFileUploadModel;
  onRemoveAttachment: (guid: string) => void;
  showAttachmentIcon?: boolean;
  showAttachmentSize?: boolean;
}
const AttachmentListItem: React.FC<IAttachmentListItemProps> = (props) => {
  return (
    <div className="attachment-card">
      <div
        className="attachment-detail ellipsis"
        title={props.attachment.file.name}
      >
        {props.showAttachmentIcon && <AttachmentIcon fillColor={Colors.$black} width={16} height={16} />}
        {props.attachment.file.name}
      </div>
      <div className="attachment-remove">
        {props.attachment.isUploaded ? (
          <span className="attachment-remove-container">
            {props.showAttachmentSize && <span className="attachment-size">({formatBytes(props.attachment.file.size)}) </span>}
            <span
              className="attachment-remove-icon"
              onClick={() => props.onRemoveAttachment(props.attachment.guid)}
            >
              <CloseXButton width={props.showAttachmentSize ? 14 : 10} height={props.showAttachmentSize ? 14 : 10} />
            </span>
          </span>
        ) : (
          <span className="fas fa-spin fa-circle-notch" style={{ fontSize: "10px" }}></span>
        )}
      </div>
      {props.attachment.uploadProgress < 1 && (
        <div
          className="upload-progress-bar"
          style={{ width: `${props.attachment.uploadProgress * 100}%` }}
        ></div>
      )}
    </div>
  );
};

export default AttachmentListItem;
