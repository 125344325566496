import React from 'react'
import ShareFileCard from '../ShareFileCard';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import 'bootstrap/dist/css/bootstrap.css';
import "../../assets/styles/index.css";
import "./DropOff.scss";

const DropOff: React.FC = (props) => {
    return (
        <>
            <div className='dropoff-container'>
                <ShareFileCard {...props} />
            </div>
        </>
    )
}

export default DropOff;