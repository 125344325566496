import React from "react";
import { StepComponent } from "./StepContainer";
import { WelcomePageConstants } from "../../helper/Constants";

interface IWelcomeScreenProps {
  menuKeys: string[];
  handleGetStarted: () => void;
}
const WelcomeScreen: React.FC<IWelcomeScreenProps> = (props) => {
  const { menuKeys } = props;
  
  return (
    <div className="welcome-screen-container">

      <StepComponent isAbsolute menuKeys={menuKeys} activeMenuIndex={-1} />
      <img className="welcome-screen-image" src={"/UploadImage.png"} alt="upload" />

      <div className="welcome-screen-content">
        <h2>
          {WelcomePageConstants.TITLE}
        </h2>
        <p>
          {WelcomePageConstants.GET_STARTED_TEXT}
        </p>

        <div className="btn-container">
          <button
            className="button-primary-green-rounded"
            onClick={props.handleGetStarted}
          >
            {WelcomePageConstants.GET_STARTED_BUTTON_TEXT}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;