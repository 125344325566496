import React, { FC } from "react";
import { TickMarkIcon } from "../../common/icons/svgIcons";


interface IStepWizardProps {
    menuKeys: string[],
    activeMenuIndex: number,
    className?: string,
    isAbsolute?: boolean
}

export const StepComponent: FC<IStepWizardProps> = (props) => {
    const { menuKeys, activeMenuIndex, isAbsolute } = props;
    return (<div className="header-step-container">
        {menuKeys.map((item, i) => {
            return <>
                {i !== 0 && <div className="horizontal-line" key={i}></div>}
                <div key={`step-${i}`} className={`step-wrapper ${i !== activeMenuIndex && (i > activeMenuIndex) ? "disabled-access-step" : ""} 
                    ${i === activeMenuIndex ? "current" : ""} 
                    ${(item?.length > 10 && !isAbsolute) ? "absolute" : ""}
                    ${i < activeMenuIndex ? "done" : ""}`}>
                    <div className={`step`} key={`step-${item}-index`}>
                        {!(i < activeMenuIndex) ? i + 1 :
                            <TickMarkIcon fillColor="#212529" />}
                    </div>
                    <div className="step-name">{item}</div>
                </div>
            </>
        })}
    </div>)
};
