import { initialUserModel, IUserModel } from "./UserViewModel";

export interface IHeaderInfo {
    companyName: string;
    companyId: number;
    contactPerson: IUserModel;
    companyLogoPath: string;
    companyWhiteLogoPath: string;
    isTestCompany: boolean;
}

export const initialHeaderInfo: IHeaderInfo= {
    companyName: "",
    companyId: 0,
    contactPerson: initialUserModel,
    companyLogoPath: "",
    companyWhiteLogoPath: "",
    isTestCompany: false,
}
