import { FC } from "react";
import { ExchangeWhiteLogo } from "../../svg/IconCollection";
import ProfileDropdown from "./ProfileDropdown";
import { IHeaderInfo } from "../../core/viewModels/HeaderInfo";
import { Home } from "../../common/icons/svgIcons";
import { getClientHubDomainURL } from "../../store/actions/oneHubActions";
import { useDispatch } from "react-redux";
import { LinkType, sessionStorageConstants } from "../../helper/Constants";

interface IHeaderProps {
  headerInfo: IHeaderInfo;
  clientId: string;
  encodedEmail?: string;
  hideProductLogo?: boolean;
  hideUTELink?: boolean;
  className?: string;
  showAlternateProfileIcon?: boolean;
  showCompanyWhiteLogo?: boolean;
}

const Header: FC<IHeaderProps> = (props) => {
  const { headerInfo, clientId, encodedEmail,showCompanyWhiteLogo, hideProductLogo,hideUTELink, children, className, showAlternateProfileIcon } = props;
  const dispatch = useDispatch();
  const uteInfo = sessionStorage.getItem(sessionStorageConstants.fromUte);
  const linkType = uteInfo
    ? LinkType.DocumentRequestLink
    : LinkType.CompanyDropOff;

  const redirectToOneHub = () => {
    dispatch(
      getClientHubDomainURL(
        clientId,
        uteInfo,
        linkType,
        (oneHubDomainURL: string) => {
          //remove ute info before redirecting to onehub
          sessionStorage.removeItem(sessionStorageConstants.fromUte);
          window.location.href = oneHubDomainURL;
        }
      )
    );
  };

  return (
    <header id="header" className={`layout-header vertical-horizontal-center ${className}`}>
     {!hideProductLogo && <div className="header-logo-name-container">
        <ExchangeWhiteLogo width={103} height={23} fillColor="#fff"/>
        <div className="separator-line"></div>
        <span className="company-name">{headerInfo.companyName}</span>
      </div>}
      {
        showCompanyWhiteLogo ? headerInfo.companyWhiteLogoPath?.length > 0 ? 
        <img src={headerInfo.companyWhiteLogoPath} alt="white company logo" className="white-company-logo" /> :
        <span className="company-name">{headerInfo.companyName}</span> : null
      }

      {
        children
      }
      <div className="header-right-container">
        {!hideUTELink && (encodedEmail || uteInfo) && (
          <span className="header-home-info" title="Home">
            <a onClick={() => redirectToOneHub()}>
              <Home />
              <span>Home</span>
            </a>
          </span>
        )}
        <ProfileDropdown headerInfo={headerInfo} showAlternateProfileIcon={showAlternateProfileIcon}/>
      </div>
    </header>
  );
};

export default Header;
