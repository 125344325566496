import React, { FC } from "react";
import { Dropdown, Popover } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Person } from "react-bootstrap-icons";
import { IHeaderInfo } from "../../core/viewModels/HeaderInfo";
import { LayoutConstants } from "../../helper/Constants";
import { PersonIcon } from "../../common/icons/svgIcons";

interface IProfileDropdownProps {
    headerInfo: IHeaderInfo;
    showAlternateProfileIcon?: boolean;
    showContactUsBtn?: boolean;
    placement?: any;
}

const ProfileDropdown: FC<IProfileDropdownProps> = (props) => {
    const {showAlternateProfileIcon,showContactUsBtn, placement} = props;

    let contactInfoPopover = (<></>);
    let mailStr = "";
    if (props.headerInfo.contactPerson) {

        mailStr = "mailto:" + props.headerInfo.contactPerson.emailAddress;

        contactInfoPopover = (
            <Popover className="contact-person-popover" id="contactInfoPopover" placement="bottom">
                <h3 className="header">{LayoutConstants.CONTACT_INFO}</h3>
                <div className="body">
                    <div className='name'>{props.headerInfo.contactPerson.firstName + " " +
                        props.headerInfo.contactPerson.lastName} </div>
                    <div className='email'>
                        <a href={mailStr}>{props.headerInfo.contactPerson.emailAddress}</a>
                    </div>
                    {props.headerInfo.contactPerson.phoneNumber && <div className='phone-no'>{props.headerInfo.contactPerson.phoneNumber} </div>}
                </div>
            </Popover>
        );
    }
    return <Dropdown className="profile-dropdown">
        <Dropdown.Toggle>
            <OverlayTrigger
                rootClose trigger="click"
                onEnter={() => { document.body.click() }}
                overlay={contactInfoPopover}
                placement={placement ?? "bottom"}>
                <div className="profile-icon">
                    {showContactUsBtn ? <span className="profile-contact-us">Contact Us</span> :showAlternateProfileIcon ? <PersonIcon width={18} height={18}/> : <Person size={15} />}
                </div>
            </OverlayTrigger>

        </Dropdown.Toggle>

    </Dropdown>
};

export default ProfileDropdown;