import React, { FC } from "react";
import { LayoutConstants } from "../../helper/Constants";

const Footer: FC = () => {
    const currentYear = new Date().getFullYear();
    return (<footer id="footer" className="footer">
        <p>{LayoutConstants.COPYRIGHTS_TEXT.replace("{year}",currentYear.toString())}</p>
        <div className="policy-links">
            <a href={LayoutConstants.TERMS_URL} target="_blank" rel="noreferrer">{LayoutConstants.TERMS_TEXT}</a>
            <span className="mx-1">-</span>
            <a href={LayoutConstants.PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">{LayoutConstants.PRIVACY_POLICY_TEXT}</a>
        </div>
    </footer>)
};

export default Footer;