import axios, { AxiosResponse } from "axios";
import { AppThunkAction } from "..";
import { ErrorAction } from "./errorActions";
import { NotificationAction, StatusType } from "./notificationActions";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { TelemetryLogger } from "../../common/Logger/AppInsights";
import { LinkType } from "../../helper/Constants";
import { actionTypes } from "../types/oneHubTypes";
import { IOneHubUserModel } from "../../core/viewModels/oneHub/MergeEmails";
import { addPeriodAtEnd } from "../../helper/HelperFunctions";
import { IUserActivity } from "../../core/viewModels/oneHub/usersActivity";

interface RequestDropSenderDetails {
  type: actionTypes.REQUEST_DROPOFF_SENDER_DETAILS;
}

interface ReceivedDropSenderDetails {
  type: actionTypes.RECEIVED_DROPOFF_SENDER_DETAILS;
  payload: IOneHubUserModel[];
}

interface CompletedDropSenderDetails {
  type: actionTypes.COMPLETED_DROPOFF_SENDER_DETAILS;
}

export type DispatchActions =
  | RequestDropSenderDetails
  | ReceivedDropSenderDetails
  | CompletedDropSenderDetails;

type KnownActions = DispatchActions | ErrorAction | NotificationAction;

const logger = TelemetryLogger.getInstance();

export const getClientHubDomainURL =
  (
    Id: string,
    encodedEmail: string,
    linkType: LinkType,
    callback: (url: string) => void
  ): AppThunkAction<KnownActions> =>
  (dispatch, getState) => {
    axios
      .get(
        `/api/OneHub/GetOnehubRedirectURL/${Id}?encodedEmail=${encodedEmail}&tPLinkType=${linkType}`
      )
      .then((response) => {
        const result = response.data;
        callback && callback(result);
      })
      .catch(function (error: any) {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage: error.message,
          statusType: StatusType.Error,
        });
        logger.trackError(
          `getClientHubDomainURL failed in dropoff with error ${error.message} for clientId: ${Id} and email: ${encodedEmail}`
        );
      });
  };

export const fetchDropOffSenderInfo =
  (
    clientId: string,
    encodedEmail: string,
    linkType: LinkType,
    callback?: any
  ): AppThunkAction<KnownActions> =>
  (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_DROPOFF_SENDER_DETAILS,
    });
    axios
      .get(
        `/api/OneHub/GetDropOffSenderInfo/${clientId}?encodedEmail=${encodedEmail}&tPLinkType=${linkType}`
      )
      .then((response: AxiosResponse<IOneHubUserModel[]>) => {
        const data = response.data;
        dispatch({
          type: actionTypes.RECEIVED_DROPOFF_SENDER_DETAILS,
          payload: data,
        });
        callback && callback(data);
      })
      .catch(function (error: any) {
        dispatch({
          type: notificationTypes.NOTIFICATION,
          statusMessage: addPeriodAtEnd(error.message),
          statusType: StatusType.Error,
        });
        dispatch({
          type: actionTypes.COMPLETED_DROPOFF_SENDER_DETAILS,
        });
        logger.trackError(
          `fetchDropOffSenderInfo failed with error ${error.message} for clientId: ${clientId}`
        );
      });
  };

  export const saveUserActivity = (
    clientId: string,
    encodedEmail: string,
    linkType: LinkType,
    userActivity: IUserActivity
  ) => {
    axios.post(
      `/api/OneHub/SaveDropOffUserActivity/${clientId}?encodedEmail=${encodedEmail}&tPLinkType=${linkType}`,
      userActivity
    );
  };