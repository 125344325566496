import moment from "moment";
import { IDropOffRecipient } from "../core/viewModels/DropffModel";
import { LinkType, sessionStorageConstants } from "./Constants";
import { IOneHubUserModel } from "../core/viewModels/oneHub/MergeEmails";

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export var inMemoryToken: Map<string, string> = new Map();
export function storeTokenInMemory(messageGuid: string, token: string) {
  inMemoryToken = new Map();
  inMemoryToken.set(messageGuid, token);
}

export const getUserIdFromEmail = (
  data: IDropOffRecipient[],
  email: string
) => {
  let userId = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].emailAddress === email && data[i].userId) {
      userId = data[i].userId ?? 0;
      break;
    }
  }
  return userId;
};

export const checkIfUrlMatchesAnyRoute = (url: string, route: string) => {
  return url.toLowerCase().includes(route);
};

export const isRequestLink = (type: LinkType) =>
  type === LinkType.DocumentRequestLink;

export const getFormattedData = (date: string) =>
  moment(date).format("MM/DD/YYYY");

export const getFileName = (name: string): string => {
  const pattern = /[\\/:*?"<>|\u00A0]/g;
  return name.replace(pattern, "_");
};

export const addPeriodAtEnd = (text: string): string => {
  if (text[text.length - 1] !== "." || !text.endsWith(".")) {
    return text + ".";
  }
  return text;
};

export const IsUteRequest = (clientId, fromUte?: string): boolean => {
  let IsUteRequest = fromUte !== undefined;
  if (!fromUte) {
    const sessionFromUte = sessionStorage.getItem(sessionStorageConstants.fromUte);
    const sessionUteClientId = sessionStorage.getItem(sessionStorageConstants.uteClientId);
    IsUteRequest = sessionFromUte && sessionUteClientId === clientId;
  }
  return IsUteRequest;
};

export const getOneHubSenderDetails = (senderInfo: IOneHubUserModel) => {
  const nameParts = senderInfo.name.split(" ");
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(" ");
  return {
    firstName: firstName,
    lastName: lastName,
    emailAddress: senderInfo.emailAddress,
  };
};

export const isMobile = (): boolean => {
  const navigatorCheck = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const widthCheck = window.matchMedia("(max-width: 600px)").matches;
  const portrait = window.matchMedia("(orientation: portrait)").matches;
  return (navigatorCheck && widthCheck && portrait) || (navigatorCheck && !widthCheck && !portrait);
}

export const isIphoneDevice = (): boolean => {
  return /iPhone|iPod/i.test(navigator.userAgent);
};