export enum OneHubEventType {
  None = 0,
  GetStarted = 1,
  Continue,
  View,
  Download,
  Forward,
  MoreActions,
  SendFilesToCPA,
}

export const enum ExchangeFileType {
  None = 0,
  ReceivedMessage = 1,
  SentMessage,
  DocumentRequest,
}

export enum ProductType {
  Exchange = 6,
}
